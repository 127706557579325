<template>
  <div class="content">
    <div>
      <a-card style="width: 100%; border-radius: 4px">
        <div class="top-title">
          <!-- <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="receipt"
            class="svg-inline--fa fa-receipt fa-fw fa-lg"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            width="32px"
            height="32px"
            style="margin-right: 5px"
          >
            <path
              fill="currentColor"
              d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z"
            ></path>
          </svg>
          {{ $t('学习模块.传感器数据') }}
        </div>
        <div class="top-middle">
          <p>
            Serial Number：<span>{{ data.serialNumber }}</span>
          </p>
          <p>
            CGM readings：<span>{{ data.cgmReadNum }}</span>
          </p>
          <p>
            BGM readings：<span>{{ data.bgmReadNum }}</span>
          </p>
          <p>
            First Readings：<span>{{ data.firstReadDate }}</span>
          </p>
          <p>
            Last Readings：<span>{{ data.lastReadDate }}</span>
          </p>
        </div>
        <div>
          <a-button class="btn" @click="gotoBack">
            <a-icon type="left" style="font-size: 20px; color: #fff" />
            {{ $t('学习模块.研究') }}
          </a-button>
          <a-button class="btn1" @click="handleExport()">
            Export Raw Data
            <a-icon type="download" style="font-size: 20px; color: rgb(127, 98, 173); margin-right: 20px" />
          </a-button>
        </div>
      </a-card>
    </div>
    <!-- Diagram图表 -->
    <div style="margin-top: 16px">
      <!-- <a-card style="width: 100%; border-radius: 4px;" class="antCardBody">
        <div>
          <div>
            <p class="paragraph"><a-icon type="area-chart" /><span style="margin:0px 10px">Diagram</span></p>
          </div>
        </div>
      </a-card> -->
      <a-collapse v-model="activeKey" :bordered="false" class="antCollp">
        <a-collapse-panel key="1" :showArrow="false">
          <template #extra>
            <!-- <setting-outlined /> -->
            <div class="pos" @click="handleCollapse">
              <a-icon :type="isFold ? 'up' : 'down'" style="color: rgb(127, 98, 173); font-size: 28px" />
              <!-- <a-icon type="up" style="color:rgb(127, 98, 173);font-size:28px"/> -->
            </div>
          </template>
          <template #header>
            <div @click.stop="handle">
              <div class="middle-box">
                <span class="paragraph">
                  <a-icon type="area-chart" class="Icon" /><span style="margin: 0px 10px">Diagram</span>
                </span>
                <!-- <span>
                  <a-icon type="down" />
                </span> -->
              </div>
              <div class="middle-box flex2-box">
                <div>
                  <a-range-picker style="width: 400px" v-model="value1" show-time format="YYYY/MM/DD HH:mm:ss" @change="dataChange"/>
                </div>
                <div class="radio-group-box">
                  <a-radio-group v-model="value2" button-style="solid" class="btnGroup">
                    <a-radio-button value="a">Prospective</a-radio-button>
                    <a-radio-button value="b">Real Time</a-radio-button>
                  </a-radio-group>
                </div>
              </div>
              <div class="flex3-box">
                <a-button :class="cgmActive ? 'cgmactive' : ''" @click="toggleDiagram('CGMRAWVALUES')"
                >CGM Raw Values</a-button
                >
                <a-button :class="smbgActive ? 'smbgactive' : ''" @click="toggleDiagram('SMBG')">SMBG</a-button>
                <a-button :class="yslActive ? 'yslactive' : ''" @click="toggleDiagram('YSI')">YSI</a-button>
                <a-button :class="RealEGVActive ? 'realegvactive' : ''" @click="toggleDiagram('REALTIMEEGV')"
                >Real TIme - EGV</a-button
                >
                <a-button :class="RealTimeActive ? 'realTimeactive' : ''" @click="toggleDiagram('REALTIMESENSORSTATUS')"
                >Real Time- Sensor Status</a-button
                >
                <a-button :class="EGVActive ? 'egvactive' : ''" @click="toggleDiagram('EGV')">EGV</a-button>
                <a-button :class="StatusActive ? 'statusactive' : ''" @click="toggleDiagram('SENSORSTATUS')"
                >Sensor Status</a-button
                >
                <a-button :class="RawValueActive ? 'rawvalueactive' : ''" @click="toggleDiagram('FILTEREDRAWVALUES')"
                >Filtered Raw Values</a-button
                >
                <a-button :class="Prospective ? 'prospective' : ''" @click="toggleDiagram('PROSPECTIVESENSITIVITY')"
                >Prospective Sensitivity</a-button
                >
                <a-button
                  :class="Retrospective ? 'retrospective' : ''"
                  @click="toggleDiagram('RETROSPECTIVESENSITIVITY')"
                >Retrospective Sensitivity</a-button
                >
                <a-button :class="Drift ? 'drift' : ''" @click="toggleDiagram('RETROSPECTIVEDRIFTFACTOR')"
                >Retrospective Drift Factor</a-button
                >
                <a-button :class="Background ? 'background' : ''" @click="toggleDiagram('BACKGROUND')"
                >Background</a-button
                >
                <a-button :class="Rate ? 'rate' : ''" @click="toggleDiagram('RATEOFCHANGE')">Rate of change</a-button>
              </div>
            </div>
          </template>
          <div id="diagramChart" style="width: 100%; height: 430px"></div>
          <div id="diagramChartBottom"></div>
          <div style="margin-top: 16px">
            <a-space>
              <a-button class="Select-btn" :class="isActive ? 'isActive' : ''" @click="handleActive">
                Select interval to exclude
              </a-button>
              <a-button class="Show-btn" @click="openExcludeModel">
                Show excluded SMGB ({{ diagramChartParam.excludedList.length }})
              </a-button>
              <a-button class="Show-egv" @click="toggleEGVBGM"> {{showEGVtitle}} </a-button>
            </a-space>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <!-- iCGM图表 -->
    <div style="margin-top: 16px">
      <a-collapse v-model="activeKey1" :bordered="false" class="antCollps">
        <a-collapse-panel key="2" :showArrow="false">
          <template #extra>
            <div class="pos" @click="handleCollapse2">
              <a-icon :type="isFold2 ? 'up' : 'down'" style="color: rgb(127, 98, 173); font-size: 28px" />
            </div>
          </template>
          <template #header>
            <div @click.stop="handle">
              <div class="middle-box">
                <div class="paragraph" style="display: flex; align-items: center">
                  <!-- <a-icon type="area-chart" class="Icon" /><span style="margin: 0px 10px">iCGM</span> -->
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="vial-circle-check"
                    class="svg-inline--fa fa-vial-circle-check fa-fw fa-lg"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    style="width: 32px"
                    height="30px"
                  >
                    <path
                      fill="currentColor"
                      d="M0 64C0 46.3 14.3 32 32 32H96h64 64c17.7 0 32 14.3 32 32s-14.3 32-32 32V266.8c-20.2 28.6-32 63.5-32 101.2c0 25.2 5.3 49.1 14.8 70.8C189.5 463.7 160.6 480 128 480c-53 0-96-43-96-96V96C14.3 96 0 81.7 0 64zM96 96v96h64V96H96zM224 368a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm211.3-43.3c-6.2-6.2-16.4-6.2-22.6 0L352 385.4l-28.7-28.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l40 40c6.2 6.2 16.4 6.2 22.6 0l72-72c6.2-6.2 6.2-16.4 0-22.6z"
                    ></path>
                  </svg>
                  <span style="margin-left: 5px">iCGM</span>
                </div>
                <span class="Icon">
                  <!-- <a-icon type="down" /> -->
                </span>
              </div>
              <!-- <div class="middle-box flex2-box">
                <div>
                  <a-range-picker style="width: 400px;margin-top:20px" v-model="value1" show-time format="YYYY/MM/DD HH:mm:ss" />
                </div>
              </div> -->
            </div>
          </template>
          <div class="icgm-btns">
            <a-row :gutter="0">
              <a-col :xs="0" :sm="10" :md="10" :lg="9" :xl="10">
                <a-radio-group default-value="b" button-style="solid" class="btn-group">
                  <a-radio-button value="a"> Simplified </a-radio-button>
                  <a-radio-button value="b"> Full </a-radio-button>
                </a-radio-group>
                <a-space class="smbg-ysi">
                  <a-button type="primary">SMBG</a-button>
                  <a-button type="primary">YSI</a-button>
                </a-space>
                <a-radio-group default-value="a" button-style="solid" class="btn-group2" @change="radioChange">
                  <a-radio-button value="a"> Date range </a-radio-button>
                  <a-radio-button value="b"> Day by day </a-radio-button>
                </a-radio-group>
              </a-col>
              <a-col :xs="0" :sm="4" :md="8" :lg="8" :xl="6">
                <div style="width: 100%; text-align: center" v-if="isSlider">
                  <a-slider
                    range
                    :step="1"
                    tipFormatter=""
                    :default-value="slider.defaultValue"
                    @change="sliderChange"
                    @afterChange="onAfterChange"
                    class="leftSlider"
                  />
                  <span class="dataspan"
                  >Day {{ slider.startDay }} {{ slider.minDate }} - Day {{ slider.endDay }} {{ slider.maxDate }}</span
                  >
                </div>
                <div style="width: 100%; display: flex; align-items: center" v-else>
                  <a-button class="prev next" @click="dayBydayChange('Prev')">Prev</a-button>
                  <span style="margin: 0px 5px">Day {{dayByday.startDay}} {{dayByday.minDate.split('-')[1]}}/{{dayByday.minDate.split('-')[2]}}/{{dayByday.minDate.split('-')[0]}}</span>
                  <a-button class="next" @click="dayBydayChange('Next')">Next</a-button>
                </div>
              </a-col>

              <a-col :xs="0" :sm="4" :md="6" :lg="4" :xl="4">
                <div style="width: 400px; display: flex; align-items: center">
                  <span style="font-weight: 600">Marker size</span>
                  <a-slider
                    id="test"
                    tipFormatter=""
                    :default-value="30" style="width: 160px"
                    class="right-slider"
                    @change="markerSizeChange"/>
                </div>
              </a-col>
              <a-col :xs="0" :sm="4" :md="6" :lg="4" :xl="4" style="text-align: right">
                <a-radio-group default-value="b" button-style="solid" class="btn-group">
                  <a-radio-button value="a"> Prospective </a-radio-button>
                  <a-radio-button value="b"> Real Time </a-radio-button>
                </a-radio-group>
              </a-col>
            </a-row>
          </div>
          <div id="icgmChart" style="width: 100%; height: 600px"></div>
          <div class="table">
            <icgm-table :data="icgmRes" />
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <!-- iCGM图表 -->
    <!-- 弹出框 -->
    <!-- <Modal ref="ModalRefs" /> -->
    <!-- MARD -->
    <div style="margin-top: 16px">
      <a-collapse v-model="activeKey2" :bordered="false" class="antCollps">
        <a-collapse-panel key="3" :showArrow="false">
          <!-- <template #extra><setting-outlined /></template> -->
          <template #extra>
            <div class="pos" @click="handleCollapse3">
              <a-icon :type="isFold3 ? 'up' : 'down'" style="color: rgb(127, 98, 173); font-size: 28px" />
            </div>
          </template>
          <template #header>
            <div @click.stop>
              <div class="middle-box">
                <span class="paragraph">
                  <a-icon type="setting" /><span style="margin: 0px 10px; color: #000">MARD</span>
                </span>
                <span class="Icon">
                  <!-- <a-icon type="down" /> -->
                </span>
              </div>
            </div>
          </template>
          <div class="table">
            <mard-table :data="icgmRes" />
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <!-- Comments -->
    <div style="margin-top: 16px; width: 100%">
      <a-row :gutter="16">
        <a-col :span="16">
          <a-collapse v-model="activeKey3" :bordered="false" class="antCollps">
            <a-collapse-panel key="4" :showArrow="false">
              <template #extra>
                <div class="pos" @click="handleCollapse3">
                  <a-icon :type="isFold3 ? 'up' : 'down'" style="color: rgb(127, 98, 173); font-size: 28px" />
                </div>
              </template>
              <template #header>
                <div @click.stop>
                  <div class="middle-box">
                    <span class="paragraph">
                      <a-icon type="wechat" /><span style="margin: 0px 10px">Comments</span>
                    </span>
                    <span class="Icon">
                      <!-- <a-icon type="down" /> -->
                    </span>
                  </div>
                </div>
              </template>
              <div class="table">
                <a-row :gutter="16">
                  <a-col :span="21">
                    <a-input placeholder="Comment" size="large" v-model="comment" />
                  </a-col>
                  <a-col :span="3">
                    <a-button
                      class="AddBtn"
                      size="large"
                      @click="addPcbaComments(comment)"
                      v-show="isEdit == false"
                      :disabled="comment == ''"
                    >
                      Add <a-icon type="wechat" />
                    </a-button>
                    <a-button class="AddBtn" size="large" @click="handleEdit" v-show="isEdit == true">
                      Edit <a-icon type="wechat" />
                    </a-button>
                  </a-col>
                </a-row>
                <a-list item-layout="horizontal" :data-source="pcbaCommentsList">
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a-list-item-meta :description="item.commentsContext">
                      <a slot="title" href="#">{{ item.createTime }}</a>
                      <a-avatar slot="avatar" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                    </a-list-item-meta>
                    <div>
                      <a-space>
                        <a slot="title" href="#" @click="Edit(item)">编辑</a>
                        <a-popconfirm
                          title="确认删除该条评论吗?"
                          ok-text="Yes"
                          cancel-text="No"
                          @confirm="delPcbaComments(item.id)"
                        >
                          <template>
                            <a href="#">删除</a>
                          </template>
                        </a-popconfirm>
                      </a-space>
                    </div>
                  </a-list-item>
                </a-list>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </a-col>
        <a-col :span="8">
          <a-collapse v-model="activeKey4" :bordered="false" class="antCollps">
            <a-collapse-panel key="5" :showArrow="false">
              <template #extra><setting-outlined /></template>
              <template #header>
                <div @click.stop>
                  <div class="middle-box">
                    <span class="paragraph"> <a-icon type="tags" /><span style="margin: 0px 10px">Tags</span> </span>
                    <span class="Icon">
                      <a-icon type="down" />
                    </span>
                  </div>
                </div>
              </template>
              <div class="table"></div>
            </a-collapse-panel>
          </a-collapse>
        </a-col>
      </a-row>
    </div>

    <!-- exclude 弹框 -->
    <exclude-model ref="excludemodelRefs" :data="diagramChartParam.excludedList" @del="handleDel"/>
  </div>
</template>

<script>
  import Highcharts from '@/utils/highcharts.js'
  import moment from 'moment'
  import Table from './modules/table.vue'
  import { getSensorData, getDiagramData, getiCGMData } from '@/api/sensor/sensor'
  import IcgmTable from './icgm-table.vue'
  import MardTable from './mard-table.vue'
  import ExcludeModel from './modules/Exclude-model.vue'
  // import Modal from './modules/Modal.vue'
  import {
    listPcbaComments,
    pagePcbaComments,
    getPcbaComments,
    addPcbaComments,
    updatePcbaComments,
    delPcbaComments,
  } from '@/api/pcbaComments/pcbaComments'
  import { Slider } from 'ant-design-vue'
  export default {
    data() {
      return {
        isFold: false,
        isFold2: false,
        isFold3: false,
        isFold4: false,

        data: {},
        //Diagram 图表全部数据
        diagramAllData: {},
        //需要移除的SMBG数据点索引
        removeSMBGList: [],
        //重新绘制数据
        reDrawDiagramAllData: {},

        comment: '', //评论内容
        //查询Diagram图表入参
        diagramChartParam: {
          endRecordedAt: '',
          serialNumber: '',
          startRecordedAt: '',
          excludedList: [],
          firstIntroducedAt: '',
          lastIntroducedAt: '',
        },
        //查询iCGM图表入参
        iCGMChartParam: {
          serialNumber: '',
          endRecordedAt: '',
          startRecordedAt: '',
          dataType: 'smbg',
          firstIntroducedAt: '',
          lastIntroducedAt: '',
        },
        //iCGM返回数据
        icgmRes: {},
        //slider默认数据
        slider: {
          minDate: '',
          maxDate: '',
          startDay: '',
          endDay: '',
          defaultValue: [0, 100],
        },
        calcSlider: {
          minDate: '',
          maxDate: '',
          diffDay: '',
        },
        //day by day
        dayByday: {
          minDate: '',
          startDay: 1,
          endDay:1
        },
        //圈的大小
        icgmMarkerRadius:4,


        //评论列表
        pcbaCommentsList: [],
        isEdit: false,
        activeKey: '1',
        activeKey1: '2',
        activeKey2: '3',
        activeKey3: '4',
        activeKey4: '5',
        //value1: ['2024/01/01 00:00:00', moment().format('YYYY/MM/DD hh:mm:ss')],
        value1:['',''],
        value2: 'b',
        cgmActive: true,
        smbgActive: true,
        yslActive: true,
        RealEGVActive: true,
        RealTimeActive: true,
        EGVActive: false,
        StatusActive: false,
        RawValueActive: false,
        Prospective: false,
        Retrospective: false,
        Drift: false,
        Background: false,
        Rate: false,
        id: '', // 评论id
        isActive: true,
        showEGVtitle: 'Show EGV / BGM deviation in %',
        marks: 'Day 3 12/29/2022 - Day 11 01/06/2023',
        isSlider: true,
      }
    },
    components: {
      Table,
      IcgmTable,
      MardTable,
      ExcludeModel,
      'a-slider': Slider,
      // Modal
    },
    created() {
      this.diagramChartParam.serialNumber = this.$route.query.serialNumber
      this.iCGMChartParam.serialNumber = this.$route.query.serialNumber
      //获取图表上部统计数据
      this.getData()
      //查询列表
      this.listPcbaComments()
    },
    mounted() {
      // this.initChart()
      // //获取Diagram图表数据
      // this.getDiagramChartData()
      // //获取iCGM图表数据
      // this.getiCGMChartData()
    },
    methods: {
      openModal() {
        this.$refs.ModalRefs.openModal()
      },
      //获取图表上部统计数据
      getData() {
        getSensorData(this.diagramChartParam).then((res) => {
          // console.log(res)
          this.data = res.data
        this.diagramChartParam.firstIntroducedAt = res.data.firstReadDate
        this.diagramChartParam.lastIntroducedAt = res.data.lastReadDate
        this.iCGMChartParam.firstIntroducedAt = res.data.firstReadDate
        this.iCGMChartParam.lastIntroducedAt = res.data.lastReadDate

        //获取Diagram图表数据
        this.getDiagramChartData()
        //获取iCGM图表数据
        this.getiCGMChartData()
      })
      },
      /** 导出按钮操作 */
      handleExport() {
        var that = this
        //that.queryParam.serialNumber = this.data.serialNumber;
        this.$confirm({
          title: this.$t('通用.文本.是否确认导出'),
          content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
          onOk() {
            that.download(
              '/deviceReading/deviceReading/export',
              that.diagramChartParam,
              `${that.diagramChartParam.serialNumber}.csv`
            )
          },
          onCancel() {},
        })
      },
      handleClick(event) {
        // event.stopPropagation()
      },
      //获取 Diagram 图表数据
      getDiagramChartData() {

        if(this.value1[0] != ''&& this.value1[1] != ''){
          // this.diagramChartParam.startRecordedAt = this.value1 ? this.value1[0].substring(0,10) + " "+ this.value1[0].substring(11,19) : ''
          // this.diagramChartParam.endRecordedAt = this.value1 ? this.value1[1].substring(0,10) + " "+ this.value1[1].substring(11,19) : ''
        }

        getDiagramData(this.diagramChartParam).then((res) => {
          console.log(res)

        this.diagramAllData = res
        this.reDrawDiagramAllData = res
        this.drawDiagramHighcharts(res)
        this.drawDiagramBottomHighcharts()
      })
      },
      toggleDiagram(value) {
        let diagramAllData = JSON.parse(JSON.stringify(this.reDrawDiagramAllData))
        if (value == 'CGMRAWVALUES') {
          this.cgmActive = !this.cgmActive
        } else if (value == 'SMBG') {
          this.smbgActive = !this.smbgActive
        } else if (value == 'YSI') {
          this.yslActive = !this.yslActive
        } else if (value == 'REALTIMEEGV') {
          this.RealEGVActive = !this.RealEGVActive
        } else if (value == 'REALTIMESENSORSTATUS') {
          this.RealTimeActive = !this.RealTimeActive
        } else if (value == 'EGV') {
          this.EGVActive = !this.EGVActive
        } else if (value == 'SENSORSTATUS') {
          this.StatusActive = !this.StatusActive
        } else if (value == 'FILTEREDRAWVALUES') {
          this.RawValueActive = !this.RawValueActive
        } else if (value == 'PROSPECTIVESENSITIVITY') {
          this.Prospective = !this.Prospective
        } else if (value == 'RETROSPECTIVESENSITIVITY') {
          this.Retrospective = !this.Retrospective
        } else if (value == 'RETROSPECTIVEDRIFTFACTOR') {
          this.Drift = !this.Drift
        } else if (value == 'BACKGROUND') {
          this.Background = !this.Background
        } else if (value == 'RATEOFCHANGE') {
          this.Rate = !this.Rate
        }

        //移除
        if (!this.cgmActive) {
          let CGMRAWVALUESArr = diagramAllData.data.value.CGMRAWVALUES
          diagramAllData.data.value.CGMRAWVALUES = CGMRAWVALUESArr.fill(null)
        } else {
          diagramAllData.data.value.CGMRAWVALUES = this.diagramAllData.data.value.CGMRAWVALUES
        }

        if (!this.smbgActive) {
          let SMBGArr = diagramAllData.data.value.SMBG
          diagramAllData.data.value.SMBG = SMBGArr.fill(null)
        } else {
          diagramAllData.data.value.SMBG = this.diagramAllData.data.value.SMBG
        }

        if (!this.yslActive) {
          let YSIArr = diagramAllData.data.value.YSI
          diagramAllData.data.value.YSI = YSIArr.fill(null)
        } else {
          diagramAllData.data.value.YSI = this.diagramAllData.data.value.YSI
        }

        if (!this.RealEGVActive) {
          let REALTIMEEGVArr = diagramAllData.data.value.REALTIMEEGV
          diagramAllData.data.value.REALTIMEEGV = REALTIMEEGVArr.fill(null)
        } else {
          diagramAllData.data.value.REALTIMEEGV = this.diagramAllData.data.value.REALTIMEEGV
        }

        //移除SMBG数据点
        let removeSMBGList = this.removeSMBGList
        for (var i = 0; i < removeSMBGList.length; i++) {
          let index = removeSMBGList[i]
          diagramAllData.data.value.SMBG[index] = null
        }

        //重新绘制图表
        this.reDrawDiagramAllData = diagramAllData
        this.drawDiagramHighcharts(diagramAllData)
        this.drawDiagramBottomHighcharts()
      },

      //绘制 Diagram 图表
      drawDiagramHighcharts(res) {
        let that = this
        let date = res.data.date
        let CGMRAWVALUES = res.data.value.CGMRAWVALUES
        let SMBG = res.data.value.SMBG
        let YSI = res.data.value.YSI
        let REALTIMEEGV = res.data.value.REALTIMEEGV
        let resREALTIMEEGVList = res.data.value.REALTIMESENEORSTATUS

        let MAXRAWVALUE = Number(res.data.value.MAXRAWVALUE)
        let REALTIMESENEORSTATUS = []



        let plotBandsList = [];

        for (var i = 0; i < resREALTIMEEGVList.length; i++) {
          if (resREALTIMEEGVList[i] === 'shutdown') {
            REALTIMESENEORSTATUS.push({ y: MAXRAWVALUE, color: '#CCCCCC', hidden: false })
          } else if (resREALTIMEEGVList[i] === 'high') {
            REALTIMESENEORSTATUS.push({ y: MAXRAWVALUE, color: '#FFF9E6', hidden: false })
          }
          else {
            REALTIMESENEORSTATUS.push({ y: 0, color: 'green', hidden: true })
          }

          // if (resREALTIMEEGVList[i] === 'high') {
          //   plotBandsList.push({
          //     from: i,
          //     to: i+1,
          //     color: 'rgba(255, 40, 0, 0.1)',
          //     label: {
          //       //text: 'high',
          //       style: {
          //         color: '#606060'
          //       }
          //     }
          //   });
          // }else if (resREALTIMEEGVList[i] === 'good') {
          //   plotBandsList.push({
          //     from: i,
          //     to: i+1,
          //     color: 'rgba(98, 226, 32, 0.1)',
          //     label: {
          //       //text: 'good',
          //       style: {
          //         color: '#606060'
          //       }
          //     }
          //   });
          // }else if (resREALTIMEEGVList[i] === 'warmup') {
          //   plotBandsList.push({
          //     from: i,
          //     to: i+1,
          //     color: 'rgba(87, 83, 84, 0.1)',
          //     label: {
          //       //text: 'good',
          //       style: {
          //         color: '#606060'
          //       }
          //     }
          //   });
          // }




        }

        Highcharts.chart('diagramChart', {
          title: {
            text: '', // 标题
          },
          // boost:{
          //   enabled:true
          // },
          chart: {
            marginLeft: 40,
            marginRight: 40,
            renderTo: 'test_container',
            type: 'scatter',
            zoomType: 'x',

            events: {
              selection(event) {
                var minX = Math.floor(Math.min(event.xAxis[0].min, event.xAxis[0].max)),
                  maxX = Math.floor(Math.max(event.xAxis[0].min, event.xAxis[0].max))
                // 你可以在这里处理你的事件逻辑
                // 例如，更新图表数据或者发送请求到服务器
                // console.log('Selection changed: X axis range from ' + minX + ' to ' + maxX)
                var startDate = date[minX]
                var endDate = date[maxX]
                that.diagramChartParam.excludedList.push({
                  systemDate: that.getCurentTime(),
                  excludedStartRecordedAt: startDate,
                  excludedEndRecordedAt: endDate,
                })
                //重新加载图表
                that.getDiagramChartData()
              },
              // legendItemClick: function (event) {
              //   // 返回 false 阻止默认的点击行为
              //   return false
              // },
            },
          },

          legend: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
          xAxis: {
            //lineColor: '#FFFFFF', //设置为白色
            lineColor: 'rgb(230, 230, 230)',
            crosshair: true,
            categories: date,
            //categories: ['2021','2022'],
            tickInterval: 100,
            labels: {
              formatter: function () {
                var date = new Date(this.value)
                return Highcharts.dateFormat('%b %e', date)
              },
              enabled: false,
            },



            plotBands:plotBandsList


          },

          yAxis: [
            {
              visible: true,
              title: {
                text: 'Raw Value [pA]',
                x: -15,
              },
              labels: {
                align: 'left',
              },
            },
            {
              visible: true,
              title: {
                text: 'Glucose Concentration and EGV [mg/dL]',
                x: 15,
              },
              labels: {
                align: 'right',
              },
              opposite: true,
            },
          ],

          // yAxis: [
          //     {
          //       visible:true,
          //       //max:24000,
          //       title: {
          //         text: 'Raw Value [pA]',
          //         x:-15
          //       },
          //       labels: {
          //         align: 'left',
          //       },
          //     },
          //     {
          //       visible:true,
          //       linkedTo: 0,
          //       //对面显示
          //       opposite: true,
          //       title: {
          //         text: 'Glucose Concentration and EGV [mg/dL]',
          //         x:15
          //       },
          //       labels: {
          //         align: 'right',
          //       },
          //     }
          //   ],

          // tooltip: {
          //
          //   //xDateFormat: '', // 设置x轴的日期格式为空字符串，即不显示
          //   // 或者使用
          //    xDateFormat: false, // 完全不显示x轴信息
          //   shared: true // 如果是多系列共享tooltip，需要设置为true
          //
          //   // split: false,
          //   // shared: false,
          //   // useHTML: true,
          //   // xDateFormat: '',
          //   // // 设置x轴提示为不可用
          //   // xAxis: {
          //   //   label: {
          //   //     enabled: false
          //   //   }
          //   // }
          //   // formatter: function() {
          //   //   // 这里返回自定义的tooltip内容，不包括x轴的值
          //   //   return '';
          //   // }
          // },

          series: [
            {
              name: 'CMG',
              //type: 'line',
              type: 'spline',
              data: CGMRAWVALUES,
              yAxis: 0,
              color: 'rgb(76, 175, 80)',
              showInLegend: false,
              tooltip: {
                // xDateFormat: '%Y-%m-%d',
                // dateTimeLabelFormats: {
                //   day: '%m-%d',
                //   month: '%Y-%m'
                // },
                //pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage}%)<br/>',
                //valueDecimals: 2,
                //split: false,
                //shared: false,
                //useHTML: true,
                //不显示默认的x轴提示
                headerFormat: '',
                pointFormatter: function (e) {
                  let x = this.x // 获取x轴索引
                  let y = this.y // 获取y轴索引
                  let color = this.color
                  let serialNumber = that.data.serialNumber
                  let seriesname = this.series.name // 获取数据点的名称
                  let date = that.formatiCGMChartDate(that.formatChartDate(that.diagramAllData.data.date[x]))
                  let value = that.formatChartDate(that.diagramAllData.data.value.CGMRAWVALUES[x])
                  let toolTiphtml =
                    '<span style="font-weight: bold;">' +
                    date +
                    '</span><br/>' +
                    '<span style="color:' +
                    color +
                    ';font-weight: bold;">Raw Value:</span><span style="color:' +
                    color +
                    '"> ' +
                    value +
                    '</span><span style="color:' +
                    color +
                    '"> pA</span><br/>' +
                    '<span style="font-weight: bold;">Serial Number: </span><span>  ' +
                    serialNumber +
                    '</span>'
                  return toolTiphtml
                },
              },
            },
            {
              name: 'SMBG',
              //type: 'spline',
              type: 'scatter',
              marker:{
                radius:5,
                symbol:'circle'
              },
              data: SMBG,
              color: '#409eff',
              yAxis: 1,
              point: {
                events: {
                  click: function () {
                    // 获取点击的点的横轴值
                    var xValue = this.x
                    // 获取点击的点的纵轴值
                    var yValue = this.y
                    // 你可以在这里处理点击事件，例如弹出信息或者更新图表等
                    //alert('点击的点横轴值为：' + xValue + ', 纵轴值为：' + yValue);
                    that.removeSMBGList.push(xValue)
                    that.toggleDiagram()
                  },
                },
              },
              tooltip: {
                headerFormat: '',
                pointFormatter: function (e) {
                  let x = this.x // 获取x轴索引
                  let y = this.y // 获取y轴索引
                  let color = this.color
                  let serialNumber = that.data.serialNumber
                  let seriesname = this.series.name // 获取数据点的名称
                  let date = that.formatiCGMChartDate(that.formatChartDate(that.diagramAllData.data.date[x]))
                  let value = that.formatChartDate(that.diagramAllData.data.value.SMBG[x])
                  let toolTiphtml =
                    '<span style="font-weight: bold;">' +
                    date +
                    '</span><br/>' +
                    '<span style="color:' +
                    color +
                    ';font-weight: bold;">Glucose Concentration:</span><span style="color:' +
                    color +
                    '"> ' +
                    value +
                    '</span><span style="color:' +
                    color +
                    '"> mg/dL</span><br/>' +
                    '<span style="font-weight: bold;">Type: </span><span> ' +
                    seriesname +
                    '</span>'
                  return toolTiphtml
                },
              },
            },
            {
              name: 'YSI',
              //type: 'line',
              type: 'spline',
              data: YSI,
              color: '#409eff',
              yAxis: 1,
              tooltip: {
                headerFormat: '',
                pointFormatter: function (e) {
                  let x = this.x // 获取x轴索引
                  let y = this.y // 获取y轴索引
                  let color = this.color
                  let serialNumber = that.data.serialNumber
                  let seriesname = this.series.name // 获取数据点的名称
                  let date = that.formatiCGMChartDate(that.formatChartDate(that.diagramAllData.data.date[x]))
                  let value = that.formatChartDate(that.diagramAllData.data.value.YSI[x])
                  let toolTiphtml =
                    '<span style="font-weight: bold;">' +
                    date +
                    '</span><br/>' +
                    '<span style="color:' +
                    color +
                    ';font-weight: bold;">Glucose Concentration:</span><span style="color:' +
                    color +
                    '"> ' +
                    value +
                    '</span><span style="color:' +
                    color +
                    '"> mg/dL</span><br/>' +
                    '<span style="font-weight: bold;">Type: </span><span> ' +
                    seriesname +
                    '</span>'
                  return toolTiphtml
                },
              },
            },
            {
              name: 'REALTIMEEGV',
              type: 'spline',
              data: REALTIMEEGV,
              color: 'rgb(207, 87, 50)',
              yAxis: 1,
              tooltip: {
                headerFormat: '',
                pointFormatter: function (e) {
                  let x = this.x // 获取x轴索引
                  let y = this.y // 获取y轴索引
                  let color = this.color
                  let serialNumber = that.data.serialNumber
                  let seriesname = this.series.name // 获取数据点的名称
                  let date = that.formatiCGMChartDate(that.formatChartDate(that.diagramAllData.data.date[x]))
                  let value = that.formatChartDate(that.diagramAllData.data.value.REALTIMEEGV[x])
                  let sensorStatus = that.formatChartDate(that.diagramAllData.data.value.REALTIMESENEORSTATUS[x])

                  let toolTiphtml =
                    '<span style="font-weight: bold;">' +
                    date +
                    '</span><br/>' +
                    '<span style="color:' +
                    color +
                    ';font-weight: bold;">Real Time-EGV:</span><span style="color:' +
                    color +
                    '"> ' +
                    value +
                    '</span><br/>' +
                    '<span style="font-weight: bold;">Sensor status: </span><span> ' +
                    sensorStatus +
                    '</span><br/>' +
                    '<span style="font-weight: bold;">Serial Number: </span><span> ' +
                    serialNumber +
                    '</span>'
                  return toolTiphtml
                },
              },
            },
            {
              name: 'REALTIMESENEORSTATUS',
              type: 'column',
              data: REALTIMESENEORSTATUS,
              pointWidth: 1, //最小支持1 柱子宽度
              turboThreshold: 0, //性能阈值检查,默认值为1000，当数据量超过这个值就会报错；如果需要关掉性能阈值检查，可以将此参数设置为 0
              tooltip: {
                headerFormat: '',
                pointFormatter: function (e) {
                  let x = this.x // 获取x轴索引
                  let y = this.y // 获取y轴索引
                  let color = this.color
                  let serialNumber = that.data.serialNumber
                  let seriesname = this.series.name // 获取数据点的名称
                  let date = that.formatiCGMChartDate(that.formatChartDate(that.diagramAllData.data.date[x]))
                  let value = that.formatChartDate(that.diagramAllData.data.value.REALTIMEEGV[x])
                  let sensorStatus = that.formatChartDate(that.diagramAllData.data.value.REALTIMESENEORSTATUS[x])
                  let toolTiphtml =
                    '<span style="font-weight: bold;">' +
                    date +
                    '</span><br/>' +
                    '<span style="font-weight: bold;">Sensor status: </span><span> ' +
                    sensorStatus +
                    '</span>'
                  return toolTiphtml
                },
              },
            },
          ],
        })
      },
      //绘制 Diagram 图表
      drawDiagramBottomHighcharts() {
        let res = this.diagramAllData
        console.log('图', res)
        let date = res.data.date
        let colors = []
        let seriesData = []
        let series = []
        let seriesname = ''
        if (this.showEGVtitle == 'Show EGV / BGM deviation in %') {
          colors.push('red')
          seriesData = res.data.value.SHOWEGVBGMDEVIATIONBAIFEN
          seriesname = 'SHOWEGVBGMDEVIATIONBAIFEN'
        } else if (this.showEGVtitle == 'Show EGV / BGM deviation in mg/dl') {
          colors.push('green')
          seriesData = res.data.value.SHOWEGVBGMDEVIATIONMGL
          seriesname = 'SHOWEGVBGMDEVIATIONMGL'
        }
        series.push({
          name: seriesname,
          type: 'column',
          data: seriesData,
        })

        Highcharts.chart('diagramChartBottom', {
          chart: {
            type: 'column',
            height: 150,
            marginBottom: 40, //底部间距/空隙
            marginLeft: 40,
            marginRight: 40,
          },
          // plotOptions: {
          //   column: {
          //     pointWidth: 1, // 设置柱子的宽度为20
          //     maxPointWidth: 1,
          //     pointPadding: 5, // 控制柱子之间的间距
          //     groupPadding: 3, // 控制同一组柱子之间的间距
          //   },
          // },
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
          title: false,
          legend: {
            enabled: false,
          },

          // tooltip: {
          //   shared: true,
          //   //useHTML: true
          //   formatter: function() {
          //     var xIndex = this.point.x; // 获取x轴索引
          //     var x = this.x; // 获取x轴索引
          //     var y = this.y; // 获取x轴索引
          //
          //     var points = this.points, // 获取所有相关数据点
          //       s = '<b>' + this.x + '</b><br/>'; // x轴的值
          //     for(var i = 0; i < points.length; i++) {
          //       s += points[i].series.name + ': ' + points[i].y + '<br/>'; // 数据点的系列名和值
          //     }
          //     s += 'CMG1' + ': 80<br/>'; // 数据点的系列名和值
          //     return s;
          //   }
          // },
          xAxis: {
            categories: date,
            tickInterval: 500,
            labels: {
              formatter: function () {
                var date = new Date(this.value)
                return Highcharts.dateFormat('%b %e', date)
              },
              enabled: true,
            },
          },
          yAxis: [
            {
              visible: true,
              //tickPositions: [-158, 0, 158],
              crosshair: true,
              title: {
                text: 'EGV/BGM',
                x: -15,
              },
              labels: {
                align: 'left',
              },
            },
          ],
          colors: colors,
          series: series,
        })
      },
      //获取 iCGM 图表数据
      getiCGMChartData() {
        let that = this
        getiCGMData(this.iCGMChartParam).then((res) => {
          console.log(res)
        this.drawiCGMHighcharts(res, that.iCGMChartParam.dataType)
        this.icgmRes = res.data
        // console.log('表格数据',res)
        this.initSlider()
      })
      },
      //绘制 iCGM 图表
      drawiCGMHighcharts(res, dataType) {
        let that = this
        //数据点
        var scatterData = []
        //辅助线
        var guideData = []
        //辅助线名称
        var guideNameData = []

        //组装数据
        if (dataType == 'smbg') {
          var smbgArr = res.data.smbg
          for (var i = 0; i < smbgArr.length; i++) {
            scatterData.push([smbgArr[i].x, smbgArr[i].y])
          }
        }
        if (dataType == 'ysi') {
          var ysiArr = res.data.ysi
          for (var i = 0; i < ysiArr.length; i++) {
            scatterData.push([ysiArr[i].x, ysiArr[i].y])
          }
        }

        //组装辅助线
        var guideArr = res.data.guideList
        for (var i = 0; i < guideArr.length; i++) {
          var x1 = parseFloat(guideArr[i].start.split(',')[0])
          var y1 = parseFloat(guideArr[i].start.split(',')[1])
          var x2 = parseFloat(guideArr[i].end.split(',')[0])
          var y2 = parseFloat(guideArr[i].end.split(',')[1])
          guideData.push([
            [x1, y1],
            [x2, y2],
          ])
          guideNameData.push(guideArr[i].title)
        }

        //组装绘制图表数据
        var seriesData = []
        seriesData.push({
          name: dataType,
          fillcolor: '#2196F3',
          color: '#2196F3',
          type: 'scatter',
          marker: {
            //改变圈的大小
            radius: this.icgmMarkerRadius,
            lineWidth: 0,
            fillColor: '#2196F3',
          },
          select: {
            enabled: false,
          },
          states: {
            hover: {
              lineWidth: 0,
            },
          },
          data: scatterData,
          tooltip: {
            headerFormat: '',
            pointFormatter: function (e) {
              let x = this.x // 获取x轴索引  132
              let y = this.y // 获取y轴索引  -38.87
              let color = this.color
              let serialNumber = that.data.serialNumber
              let seriesname = this.series.name // 获取数据点的名称
              let value = that.formatChartDate(that.diagramAllData.data.value.REALTIMEEGV[x])
              let sensorStatus = that.formatChartDate(that.diagramAllData.data.value.REALTIMESENEORSTATUS[x])
              let date = that.formatiCGMChartDate(that.getiCGMrecordedAt(x, y))

              let toolTiphtml =
                '<span style="font-weight: bold;">Serial Number: </span><span> ' +
                serialNumber +
                '</span><br/>' +
                '<span style="font-weight: bold;">Glucose Difference: </span><span> ' +
                y +
                '</span><span> mg/dL</span><br/>' +
                '<span style="font-weight: bold;">Difference Glucose: </span><span> ' +
                x +
                '</span><span> mg/dL</span><br/>' +
                '<span style="font-weight: bold;">Day: </span><span> ' +
                date +
                '</span><br/>'

              return toolTiphtml
            },
          },
        })

        //循环放辅助线数据
        for (var i = 0; i < guideData.length; i++) {
          seriesData.push({
            name: guideNameData[i],
            data: guideData[i],
            type: 'line',
            dashStyle: 'LongDash',
            lineWidth: 1.5,
            color: '#4C2529',
            marker: {
              enabled: false,
            },
            dataLabels: {
              backgroundColor: 'red',
              formatter: function (e) {
                return ''
              },
              style: { color: 'contrast', fontSize: '11px', fontWeight: 'bold' },
              shadow: false,
            },
            tooltip: {
              headerFormat: '<span style="font-size:10px">{series.name}</span><table>',
              pointFormat: '',
              footerFormat: '',
              shared: true,
              useHTML: true,
            },
          })
        }

        Highcharts.chart('icgmChart', {
          chart: {
            type: 'scatter',
            zoomType: 'xy',
            height: 600,
          },
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
          title: false,
          legend: {
            enabled: false,
          },
          xAxis: {
            type: 'category',
            min: 40, // 设置X轴的最小值
            max: 400, // 设置X轴的最大值
            tickInterval: 50,
            startOnTick: false,
            endOnTick: false,
            title: {
              enabled: true,
              text: 'Reference Glucose [mg/dL]',
            },
            plotLines: [
              {
                //基准线
                color: '#CCC',
                dashStyle: 'solid',
                width: 2,
                value: 70,
                zIndex: 5,
              },
              {
                //基准线
                color: '#CCC',
                dashStyle: 'solid',
                width: 2,
                value: 180,
                zIndex: 5,
              },
            ],
          },
          yAxis: {
            type: 'category',
            min: -350,
            max: 200,
            tickInterval: 50,
            startOnTick: false,
            endOnTick: false,
            gridLineWidth: 0,
            title: {
              text: 'Glucose Difference [mg/dL]',
            },
            plotLines: [
              {
                //基准线
                color: '#CCC',
                dashStyle: 'solid',
                width: 2,
                value: 0,
                zIndex: 5,
              },
            ],
          },

          plotOptions: {
            scatter: {
              marker: {
                radius: 5,
                states: {
                  hover: {
                    enabled: true,
                    lineColor: 'rgb(100,100,100)',
                  },
                },
              },
              states: {
                hover: {
                  marker: {
                    enabled: false,
                  },
                },
              },
            },
          },
          series: seriesData,
        })
      },
      //获取iCGM对应的时间
      getiCGMrecordedAt(x, y) {
        let arr = []
        if (this.iCGMChartParam.dataType == 'smbg') {
          arr = this.icgmRes.smbg
        } else if (this.iCGMChartParam.dataType == 'ysi') {
          arr = this.icgmRes.ysi
        }
        let result = {}
        arr.forEach((item) => {
          if (item.x == x && item.y == y) {
          result = item
          return item
        }
      })
        return result.recordedAt
      },

      //获取当前时间
      getCurentTime() {
        var now = new Date()
        var year = now.getFullYear() //年
        var month = now.getMonth() + 1 //月
        var day = now.getDate() //日

        var hh = now.getHours() //时
        var mm = now.getMinutes() //分
        var sec = now.getSeconds() //秒

        var clock = year + '-'

        if (month < 10) clock += '0'

        clock += month + '-'

        if (day < 10) clock += '0'

        clock += day + ' '

        if (hh < 10) clock += '0'

        clock += hh + ':'
        if (mm < 10) clock += '0'
        clock += mm

        clock += ':'
        if (sec < 10) clock += '0'
        clock += sec
        return clock
      },
      //格式化图表日期
      formatChartDate(date) {
        return date
      },
      //格式化图表日期
      formatiCGMChartDate(date) {
        let formatDate =
          date.substring(5, 7) + '/' + date.substring(8, 10) + '/' + date.substring(0, 4) + ' ' + date.substring(11, 19)
        if (date.substring(11, 13) <= 12) {
          formatDate += ' AM'
        } else if (date.substring(11, 13) > 12) {
          formatDate += ' PM'
        }
        return formatDate
      },

      //新增评论
      addPcbaComments(commentsContext) {
        let addPcbaCommentsParam = {}
        addPcbaCommentsParam.serialNumber = this.diagramChartParam.serialNumber
        addPcbaCommentsParam.commentsContext = commentsContext
        addPcbaComments(addPcbaCommentsParam).then((res) => {
          this.$message.success('新增成功!', 3)
        this.listPcbaComments()
        this.comment = ''
      })
      },
      //删除评论
      delPcbaComments(id) {
        let delPcbaCommentsParam = {}
        delPcbaCommentsParam.id = id
        delPcbaComments(delPcbaCommentsParam).then((res) => {
          this.$message.success('删除成功!', 3)
        this.listPcbaComments()
      })
      },
      //修改评论
      updatePcbaComments(id, commentsContext) {
        let updatePcbaCommentsParam = {}
        updatePcbaCommentsParam.id = id
        updatePcbaCommentsParam.commentsContext = commentsContext
        updatePcbaComments(updatePcbaCommentsParam).then((res) => {
          this.$message.success('修改成功!', 3)
        this.isEdit = false
        this.comment = ''
        this.listPcbaComments()
      })
      },
      //查询评论列表
      listPcbaComments() {
        let listPcbaCommentsParam = {}
        listPcbaCommentsParam.serialNumber = this.diagramChartParam.serialNumber
        listPcbaComments(listPcbaCommentsParam).then((res) => {
          this.pcbaCommentsList = res.data
        // console.log('评论', this.pcbaCommentsList)
      })
      },
      // 提交编辑
      handleEdit() {
        this.updatePcbaComments(this.id, this.comment)
      },
      Edit(e) {
        this.isEdit = true
        this.comment = e.commentsContext
        this.id = e.id
      },
      handleActive() {
        this.isActive = !this.isActive
      },
      toggleEGVBGM() {
        if (this.showEGVtitle == 'Show EGV / BGM deviation in %') {
          this.showEGVtitle = 'Show EGV / BGM deviation in mg/dl'
        } else if (this.showEGVtitle == 'Show EGV / BGM deviation in mg/dl') {
          this.showEGVtitle = 'Show EGV / BGM deviation in %'
        }
        this.drawDiagramBottomHighcharts()
      },
      openExcludeModel() {
        this.$refs.excludemodelRefs.showModal()
      },
      handleCollapse() {
        // this.activeKey = '1'
        this.isFold = !this.isFold
      },
      handleCollapse2() {
        this.isFold2 = !this.isFold2
      },
      handleCollapse3() {
        this.isFold3 = !this.isFold3
      },
      gotoBack() {
        this.$router.back()
      },
      handle() {},
      //初始化slider数据
      initSlider() {
        let minDate = this.icgmRes.dateRange.min
        let maxDate = this.icgmRes.dateRange.max
        let diffDay = this.getDaysDiff(minDate, maxDate)
        this.slider.minDate = minDate.split('-')[1] + '/' + minDate.split('-')[2] + '/' + minDate.split('-')[0]
        this.slider.maxDate = maxDate.split('-')[1] + '/' + maxDate.split('-')[2] + '/' + maxDate.split('-')[0]
        this.slider.startDay = '1'
        this.slider.endDay = diffDay

        this.calcSlider.minDate = this.icgmRes.dateRange.min
        this.calcSlider.maxDate = this.icgmRes.dateRange.max
        this.calcSlider.diffDay = diffDay;

        //day by day 数据
        this.dayByday.minDate = minDate;
        this.dayByday.startDay = 1;
        this.dayByday.endDay = diffDay
      },
      //计算两个日期相差天数
      getDaysDiff(date1, date2) {
        var date1 = new Date(date1)
        var date2 = new Date(date2)
        var diffMilliseconds = Math.abs(date2 - date1)
        var diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24))
        return diffDays
      },
      //获取某个日期往后多少天日期
      getFutureDate(dateStr, days) {
        const date = new Date(dateStr)
        date.setDate(date.getDate() + days)
        const year = date.getFullYear()
        const month = date.getMonth() + 1 // 月份是从0开始的
        const day = date.getDate()
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
      },
      //重新绘制iCGM图表数据
      reloadICgm(startDate, endDate) {
        let allICGMdata = JSON.parse(JSON.stringify(this.icgmRes))
        let smbg = allICGMdata.smbg
        let ysi = allICGMdata.ysi

        let newSmbg = []
        for (var i = 0; i < smbg.length; i++) {
          let a = parseInt(startDate.replace(/-/g, ''))
          let b = parseInt(smbg[i].recordedAt.substring(0, 10).replace(/-/g, ''))
          let c = parseInt(endDate.replace(/-/g, ''))
          if (a <= b && b <= c) {
            newSmbg.push(smbg[i])
          }
        }
        let newYsi = []
        for (var i = 0; i < ysi.length; i++) {
          let a = parseInt(startDate.replace(/-/g, ''))
          let b = parseInt(ysi[i].recordedAt.substring(0, 10).replace(/-/g, ''))
          let c = parseInt(endDate.replace(/-/g, ''))
          if (a <= b && b <= c) {
            newYsi.push(ysi[i])
          }
        }
        //重新加载图表
        allICGMdata.smbg = newSmbg
        allICGMdata.ysi = newYsi
        let res = new Object()
        res.data = allICGMdata
        this.drawiCGMHighcharts(res, this.iCGMChartParam.dataType)
      },

      sliderChange(value) {
        let minValue = value[0]
        let maxValue = value[1]
        //计算最小值占用百分比
        let minRate = (minValue - 0) / 100
        //计算最大值占用百分比
        let maxRate = (100 - maxValue) / 100
        //计算距离开始结束时间天数
        let leftDay = parseInt(this.calcSlider.diffDay * minRate) != 0 ? parseInt(this.calcSlider.diffDay * minRate) : 1
        let rightDay = parseInt(this.calcSlider.diffDay * maxRate)

        let startDate = this.getFutureDate(this.calcSlider.minDate, leftDay)
        let endDate = this.getFutureDate(this.calcSlider.maxDate, -rightDay)

        //刷新silder的日期
        this.slider.minDate = startDate.split('-')[1] + '/' + startDate.split('-')[2] + '/' + startDate.split('-')[0]
        this.slider.maxDate = endDate.split('-')[1] + '/' + endDate.split('-')[2] + '/' + endDate.split('-')[0]
        this.slider.startDay = leftDay
        this.slider.endDay = this.calcSlider.diffDay - rightDay

        //重新绘制图表
        this.reloadICgm(startDate, endDate)
      },
      onAfterChange(value) {},

      //radiochange
      radioChange(e) {
        // console.log(e.target.value)
        if (e.target.value == 'b') {
          this.isSlider = false
        } else {
          this.isSlider = true
        }
      },
      //day by day 日期切换
      dayBydayChange(value){
        if (value == 'Prev'){
          if(this.dayByday.startDay - 1 < 1){
            //不处理
          }else{
            this.dayByday.startDay = parseInt(this.dayByday.startDay) - 1;
            this.dayByday.minDate = this.getFutureDate(this.dayByday.minDate, -1);
          }
        }else if (value == 'Next'){
          if(this.dayByday.startDay + 1 > this.dayByday.endDay){
            //不处理
          }else{
            this.dayByday.startDay = parseInt(this.dayByday.startDay) + 1;
            this.dayByday.minDate = this.getFutureDate(this.dayByday.minDate, 1);
          }
        }
        //重新绘制图表
        this.reloadICgm(this.dayByday.minDate, this.dayByday.minDate)

      },
      //日期变化
      dataChange(e){
        let startData = moment(e[0]._d).format('YYYY-MM-DD HH:mm:ss')
        let endData = moment(e[1]._d).format('YYYY-MM-DD HH:mm:ss')
        this.diagramChartParam.startRecordedAt = startData
        this.diagramChartParam.endRecordedAt = endData
        this.getDiagramChartData()
      },

      //更改SMBG气泡大小
      markerSizeChange(value) {
        let radius =  Math.ceil(value/8);
        if (radius < 2){
          radius = 2;
        }else if (radius > 8){
          radius = 8;
        }
        this.icgmMarkerRadius = radius;
        //重新加载图表
        let startDate = this.calcSlider.minDate;
        let endDate = this.calcSlider.maxDate;
        this.reloadICgm(startDate, endDate)
      },
      // 删除表格数据 请求图表数据
      handleDel(e){
        // this.diagramChartParam.excludedList.splice(e , 1)
        this.getDiagramChartData()
      }
    },
  }
</script>

<style scoped lang="less">
  .content {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .top-title {
      align-items: center;
      display: flex;
      font-size: 1.6rem;
      font-weight: 600;
      color: #212529;
    }

    .top-middle {
      margin-top: 8px;
      font-size: 16px;
      color: #212529;
      font-family: Poppins, sans-serif;
      p {
        margin-bottom: 0px;
        font-family: Poppins, sans-serif;
        font-size: 15px;
        span {
          font-weight: 600;
          font-family: Roboto Mono, monospace;
          font-size: 16px;
          color: #212529;
        }
      }
    }

    .btn {
      color: rgb(127, 98, 173);
      background-color: rgb(127, 98, 173);
      font-weight: 500;
      font-size: 16px;
      margin-top: 16px;
      border-radius: 0.25rem;
      height: 38px;
      color: #fff;
      // border: none !important;
    }
    .btn1 {
      background-color: none !important;
      border: none !important;
      color: rgb(127, 98, 173);
      margin-left: 20px;
    }
  }

  .paragraph {
    font-size: 1.6rem;
    font-weight: 600;
    color: #212529;
  }

  /deep/.antCardBody {
    .ant-card-body {
      padding: 10px 20px;
    }
  }

  /deep/.antCollp {
    // i {
    //   font-size: 28px !important;
    //   color: #7f62ad !important;
    //   font-weight: 700 !important;
    // }

    .paragraph {
      i {
        color: #000 !important;
      }
    }

    .middle-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .flex2-box {
      margin-top: 20px;
      .radio-group-box {
        .btnGroup {
          .ant-radio-button-wrapper-checked {
            background: #7f62ad !important;
          }
          .ant-radio-button-wrapper-checked::before {
            background-color: none !important;
          }
        }
      }
    }
    .flex3-box {
      // margin-top: 20px;
      button {
        margin-right: 10px !important;
        margin-top: 15px;
        border-radius: 0.25rem !important;
      }

      .cgmactive {
        background-color: rgb(76, 175, 80) !important;
        color: #fff !important;
      }

      .smbgactive {
        background-color: #409eff !important;
        color: #fff !important;
      }
      .yslactive {
        background-color: #409eff !important;
        color: #fff !important;
      }
      .realegvactive {
        background-color: rgb(207, 87, 50) !important;
        color: #fff !important;
      }
      .realTimeactive {
        background-color: rgb(121, 85, 72) !important;
        color: #fff !important;
      }

      .egvactive {
        background-color: rgb(255, 152, 0) !important;
        color: #fff !important;
      }
      .statusactive {
        background-color: rgb(121, 85, 72) !important;
        color: #fff !important;
      }
      .rawvalueactive {
        background-color: rgb(127, 98, 173) !important;
        color: #fff !important;
      }
      .prospective {
        background-color: rgb(63, 81, 181) !important;
        color: #fff !important;
      }
      .retrospective {
        background-color: rgb(255, 87, 34) !important;
        color: #fff !important;
      }
      .drift {
        background-color: rgb(96, 125, 139) !important;
        color: #fff !important;
      }
      .background {
        background-color: rgb(162, 212, 104) !important;
        color: #fff !important;
      }
      .rate {
        background-color: rgb(153, 0, 102) !important;
        color: #fff !important;
      }
    }
  }

  .antCollps {
    .middle-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .Icon {
        font-size: 28px;
        color: rgb(127, 98, 173) !important;
        font-weight: bold;
      }
    }
  }

  /deep/.AddBtn {
    background-color: rgb(127, 98, 173) !important;
    color: #fff;
    border-radius: 0.25rem !important;
  }

  /deep/ .Select-btn {
    border: 1px solid rgb(127, 98, 173) !important;
    border-radius: 0.25rem;
    color: rgb(127, 98, 173);
  }

  .isActive {
    background-color: rgb(127, 98, 173) !important;
    color: #fff !important;
  }

  .Show-btn,
  .Show-egv {
    border: 1px solid rgb(127, 98, 173) !important;
    border-radius: 0.25rem;
    background-color: rgb(127, 98, 173) !important;
    color: #fff;
  }

  .pos {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  .icgm-btns {
    margin: 10px 0px;
    // display: flex;
    align-items: center;
    // justify-content: space-between;
    .smbg-ysi {
      margin: 0px 10px;
    }

    .dataspan {
      line-height: 20px;
      margin-top: 0px;
    }

    /deep/.leftSlider {
      margin-bottom: 5px !important;

      .ant-slider-rail {
        background: linear-gradient(#5f5f5f, #5f5f5f) no-repeat 50%;
      }
      .ant-slider-track {
        background-color: #5f5f5f;
      }
      .ant-slider-handle {
        background-color: #7f62ad;
        border-color: #7f62ad;
      }
    }

    /deep/ .right-slider {
      margin-bottom: 13px;
      .ant-slider-step {
        background-color: #d9dde1;
        height: 9px;
        border-radius: 10px;
      }
      .ant-slider-handle {
        background-color: #7f62ad;
        width: 16px;
        height: 16px;
        border-color: #7f62ad;
      }
    }
  }

  /deep/.btn-group,
  .btn-group2 {
    border-radius: 0.3rem;

    .ant-radio-button-wrapper-checked {
      background-color: #7f62ad;
      border-color: #7f62ad;
      color: #fff !important;
    }
    .ant-radio-button-wrapper-checked:hover {
      background-color: #7f62ad;
      border-color: #7f62ad;
    }

    .ant-radio-button-wrapper {
      color: #7f62ad;
    }
    .ant-radio-button-wrapper:hover {
      background-color: #7f62ad;
      color: #fff;
      border-color: #7f62ad;
    }
  }

  .next {
    color: rgb(127, 98, 173);
    background-color: rgb(127, 98, 173);
    font-weight: 500;
    font-size: 16px;
    border-radius: 0.25rem;
    height: 38px;
    color: #fff;
  }

  .prev{

  }
</style>